<script>
import { InputCheckBox } from '@/components/Input';
import { masterMethods, checkPermission } from '@/state/helpers';
import { showMessage } from '@/utils/messages';

import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';

export default {
    page: {
        title: '権限管理一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        LoadingIcon,
        InputCheckBox
    },
    data() {
        return {
            emptyData: false,
            listDataPermissionRoles: [],
            listDataPermission: [],
            total: 0,
            limit: 10,
            page: 1,
            disabled: true,
            isLoading: false
        };
    },
    mounted() {
        this.getListPermission();
        this.getPermission();
    },
    methods: {
        ...masterMethods,
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },
        getListPermission() {
            this.listPermission().then((data) => {
                this.listDataPermissionRoles = data;
            });
        },
        getPermission() {
            this.isLoading = true;
            this.dataPermission().then((data) => {
                for (let element of data) {
                    element.per = {};
                    for (let item of element.permissions) {
                        element.per[item.name + '__' + item.id] = true;
                    }
                }
                this.listDataPermission = data;
                this.isLoading = false;
            });
        },
        goToEdit(path, item) {
            this.$router.push({ path: path, query: { id: item.id } });
        },
        savePermission() {
            let objectData = this.listDataPermission;
            for (let item of objectData) {
                let permissions = item.per;
                item.permissions = [];
                Object.keys(permissions).forEach(function (key) {
                    if (permissions[key]) {
                        let permiss = key.split('__');
                        item.permissions.push(parseInt(permiss[1]));
                    }
                });
            }
            this.registerPermission({ roles: objectData }).then((data) => {
                if (data.code == 200) {
                    showMessage(data.code, this.$bvToast, '権限が更新されました。');
                } else {
                    showMessage(data.code, this.$bvToast, this.$t(data.message));
                }
                this.disabled = true;
            });
        }
    }
};
</script>

<template>
    <Layout>
        <div class="row form__common--center" v-if="!isLoading">
            <div class="col-lg-12">
                <div class="card form--sroll mb-0">
                    <div class="card-header">
                        <h3 class="mb-0 title--screen">権限グループ一覧</h3>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table mb-0 table-bordered">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th v-for="item in listDataPermission" :key="'r' + item.id" style="min-width: 130px" class="text-center">
                                            {{ $t(item.name) }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template>
                                        <tr v-for="item in listDataPermissionRoles" :key="item.id">
                                            <td>
                                                {{ $t(item.name) }}
                                            </td>
                                            <td v-for="role in listDataPermission" :key="'l' + role.id" class="text-center">
                                                <InputCheckBox
                                                    :id="`checkbox__role${item.id}`"
                                                    :model.sync="role.per[item.name + '__' + item.id]"
                                                    :value="item.id"
                                                    :label="``"
                                                    :disabled="disabled"
                                                />
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            v-if="checkPermiss('permission.register') && disabled"
                            @click="disabled = !disabled"
                            class="btn btn-primary ml-3"
                        >
                            {{ $t('btn.edit') }}
                        </button>
                        <button
                            type="button"
                            v-if="checkPermiss('permission.register') && !disabled"
                            @click="savePermission()"
                            class="btn btn-primary ml-3"
                        >
                            {{ $t('btn.save') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
